@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Raleway:wght@400;600&display=swap');

/* Import themes */
@import url('themes/Aviva.css');
@import url('themes/DayInsure.css');
@import url('themes/RAC.css');
@import url('themes/Halifax.css');
@import url('themes/Jaunt.css');
@import url('themes/QuoteMeHappy.css');
@import url('themes/GeneralAccident.css');
@import url('themes/Autonet.css');
@import url('themes/Cornmarket.css');
@import url('themes/Vauxhall.css');
@import url('themes/4YoungDrivers.css');
@import url('themes/RedDrivingSchool.css');
@import url('themes/Stellantis.css');
@import url('themes/Principal.css');
@import url('themes/Everyday.css');
@import url('themes/Comfort.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Raleway', system-ui, sans-serif;
    -webkit-font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum';
    font-variant-numeric: lining-nums;
  }
  /* this removes edge reveal button DAYIN-291 */
  ::-ms-reveal {
    display: none;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-variant-numeric: lining-nums;
    font-feature-settings: 'lnum';
  }
}

@layer components {
  .field-base {
    @apply flex px-4 w-full h-14 items-center lg:px-6 lg:h-16;
  }

  .selector-base {
    @apply flex px-4 w-full h-14 items-center lg:px-8 lg:h-16;
  }

  .slider-thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    @apply appearance-none w-8 h-8 rounded-full bg-slider-active cursor-pointer;
  }

  .slider-thumb::-moz-range-thumb {
    @apply w-8 h-8 rounded-full bg-slider-active cursor-pointer;
  }

  .datepicker {
    --rdp-accent-color: rgba(var(--popup-selector-active));
    --rdp-background-color: rgba(var(--popup-background));
    --rdp-outline: 2px solid rgb(var(--rdp-accent-color));
    --rdp-outline-selected: 2px solid rgb(var(--rdp-accent-color));
  }
  @layer utilities {
    .remove-bottom-radius {
      -webkit-appearance: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      @apply rounded-t-lg;
    }
    .remove-top-radius {
      -webkit-appearance: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      @apply rounded-b-lg;
    }
  }
}
