.Cornmarket {
  --page-background-from: 255, 255, 255, 1;
  --page-background-to: 255, 255, 255, 1;
  --header-background: 238, 248, 240, 1;
  --header-content-1: 60, 16, 83, 1;
  --header-content-2: 60, 16, 83, 1;
  --header-content-3: 60, 16, 83, 0.6;
  --main-content-1: 91, 91, 91, 1;
  --main-content-2: 91, 91, 91, 0.6;
  --main-content-3: 91, 91, 91, 0.4;
  --main-content-4: 91, 91, 91, 0.2;
  --main-content-5: 91, 91, 91, 0.1;
  --main-content-6: 91, 91, 91, 0.05;
  --content-background: 91, 91, 91, 0.05;
  --content-background-prominent: 91, 91, 91, 0.1;
  --cta-primary: 60, 16, 83, 1;
  --cta-primary-text: 255, 255, 255, 1;
  --cta-primary-hover: 80, 40, 100, 1;
  --cta-secondary: 255, 255, 255, 0.1;
  --cta-secondary-text: 0, 147, 178, 1;
  --cta-secondary-border: 0, 147, 178, 1;
  --cta-secondary-hover: 91, 91, 91, 0.05;
  --selector-active: 0, 147, 178, 1;
  --selector-active-text: 255, 255, 255, 1;
  --selector-border-1: 0, 147, 178, 1;
  --selector-border-2: 0, 147, 178, 0.2;
  --selector-isolated-active: 0, 147, 178, 1;
  --slider-active: 0, 147, 178, 1;
  --slider-inactive: 0, 147, 178, 0.2;
  --slider-handle: 255, 255, 255, 1;
  --reg-plate-text: 91, 91, 91, 1;
  --reg-plate: 91, 91, 91, 0.4;
  --progress-1: 0, 147, 178, 1;
  --progress-2: 0, 147, 178, 0.2;
  --summary-1: 60, 16, 83, 1;
  --summary-2: 60, 16, 83, 0.9;
  --footer-background: 52, 52, 52, 1;
  --footer-content-1: 255, 255, 255, 1;
  --footer-content-2: 255, 255, 255, 0.6;
  --link: 0, 147, 178, 1;
  --summary-link: 0, 147, 178, 1;
  --error-text: 255, 0, 0, 1;
  --error-border: 255, 0, 0, 1;
  --menu-background: 0, 147, 178, 1;
  --menu-content-1: 255, 255, 255, 1;
  --menu-content-2: 255, 255, 255, 0.1;
  --popup-background: 91, 91, 91, 0.05;
  --popup-title: 91, 91, 91, 1;
  --popup-content-1: 91, 91, 91, 1;
  --popup-content-2: 91, 91, 91, 0.6;
  --popup-secondary-cta-text: 0, 147, 178, 1;
  --popup-secondary-cta-border: 0, 147, 178, 1;
  --popup-selector-active: 0, 147, 178, 1;
  --popup-selector-active-text: 255, 255, 255, 1;
  --popup-selector-border: 91, 91, 91, 0.4;
  --popup-link: 0, 147, 178, 1;
  --chat-background: 0, 147, 178, 1;
  --chat-text: 255, 255, 255, 1;
}
