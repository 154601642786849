.RedDrivingSchool {
  --page-background-from: 255, 255, 255, 1;
  --page-background-to: 255, 255, 255, 1;
  --header-background: 247, 247, 247, 1;
  --header-content-1:  47, 47, 47, 1;
  --header-content-2:  47, 47, 47, 1;
  --header-content-3: 47, 47, 47, 0.6;
  --main-content-1: 47, 47, 47, 1;
  --main-content-2: 47, 47, 47, 0.6;
  --main-content-3: 47, 47, 47, 0.4;
  --main-content-4: 47, 47, 47, 0.2;
  --main-content-5: 47, 47, 47, 0.1;
  --main-content-6: 47, 47, 47, 0.05;
  --content-background: 47, 47, 47, 0.05;
  --content-background-prominent: 47, 47, 47, 0.1;
  --cta-primary: 223, 43, 47, 1;
  --cta-primary-text: 255, 255, 255, 1;
  --cta-primary-hover: 226, 64, 67, 1;
  --cta-secondary: 255, 255, 255, 0.1;
  --cta-secondary-text: 223, 43, 47, 1;
  --cta-secondary-border: 223, 43, 47, 1;
  --cta-secondary-hover: 47, 47, 47, 0.05;
  --selector-active:  223, 43, 47, 1;
  --selector-active-text: 255, 255, 255, 1;
  --selector-border-1: 223, 43, 47, 1;
  --selector-border-2: 223, 43, 47, 0.2;
  --selector-isolated-active: 223, 43, 47, 1;
  --slider-active: 223, 43, 47, 1;
  --slider-inactive: 223, 43, 47, 0.2;
  --slider-handle: 255, 255, 255, 1;
  --reg-plate-text: 47, 47, 47, 1;
  --reg-plate: 47, 47, 47, 0.4;
  --progress-1: 47, 47, 47, 1;
  --progress-2: 47, 47, 47, 0.2;
  --summary-1: 47, 47, 47, 1;
  --summary-2: 47, 47, 47, 0.9;
  --footer-background: 47, 47, 47, 1;
  --footer-content-1: 255, 255, 255, 1;
  --footer-content-2: 255, 255, 255, 0.6;
  --link: 223, 43, 47, 1;
  --summary-link: 223, 43, 47, 1;
  --error-text: 255, 0, 0, 1;
  --error-border: 255, 0, 0, 1;
  --menu-background: 47, 47, 47, 1;
  --menu-content-1: 255, 255, 255, 1;
  --menu-content-2: 255, 255, 255, 0.1;
  --popup-background:  91, 91, 91, 0.05;
  --popup-title: 47, 47, 47, 1;
  --popup-content-1: 47, 47, 47, 1;
  --popup-content-2: 47, 47, 47, 0.6;
  --popup-secondary-cta-text: 223, 43, 47, ;
  --popup-secondary-cta-border: 2223, 43, 47, ;
  --popup-selector-active: 223, 43, 47, 1;
  --popup-selector-active-text: 255, 255, 255, 1;
  --popup-selector-border: 47, 47, 47, 0.4;
  --popup-link: 223, 43, 47, 1;
  --chat-background: 223, 43, 47, 1;
  --chat-text: 255, 255, 255, 1;
}
