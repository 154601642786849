.Autonet {
  --page-background-from: 255, 255, 255, 1;
  --page-background-to: 255, 255, 255, 1;
  --header-background: 236, 233, 227, 1;
  --header-content-1: 85, 85, 85, 1;
  --header-content-2: 85, 85, 85, 1;
  --header-content-3: 85, 85, 85, 0.6;
  --main-content-1: 85, 85, 85, 1;
  --main-content-2: 85, 85, 85, 0.6;
  --main-content-3: 85, 85, 85, 0.4;
  --main-content-4: 85, 85, 85, 0.2;
  --main-content-5: 85, 85, 85, 0.1;
  --main-content-6: 85, 85, 85, 0.05;
  --content-background: 236, 233, 227, 0.4;
  --content-background-prominent: 236, 233, 227, 1;
  --cta-primary: 247, 166, 0, 1;
  --cta-primary-text: 255, 255, 255, 1;
  --cta-primary-hover: 247, 175, 25, 1;
  --cta-secondary: 255, 255, 255, 0.1;
  --cta-secondary-text: 98, 110, 162, 1;
  --cta-secondary-border: 98, 110, 162, 1;
  --cta-secondary-hover: 236, 233, 227, 0.4;
  --selector-active: 98, 110, 162, 1;
  --selector-active-text: 255, 255, 255, 1;
  --selector-border-1: 98, 110, 162, 1;
  --selector-border-2: 98, 110, 162, 0.2;
  --selector-isolated-active: 98, 110, 162, 1;
  --slider-active: 98, 110, 162, 1;
  --slider-inactive: 98, 110, 162, 0.2;
  --slider-handle: 255, 255, 255, 1;
  --reg-plate-text: 85, 85, 85, 1;
  --reg-plate: 85, 85, 85, 0.4;
  --progress-1: 98, 110, 162, 1;
  --progress-2: 98, 110, 162, 0.2;
  --summary-1: 98, 110, 162, 1;
  --summary-2: 98, 110, 162, 0.9;
  --footer-background: 236, 233, 227, 1;
  --footer-content-1: 85, 85, 85, 1;
  --footer-content-2: 85, 85, 85, 0.6;
  --link: 98, 110, 162, 1;
  --summary-link: 98, 110, 162, 1;
  --error-text: 255, 0, 0, 1;
  --error-border: 255, 0, 0, 1;
  --menu-background: 85, 85, 85, 1;
  --menu-content-1: 255, 255, 255, 1;
  --menu-content-2: 255, 255, 255, 0.1;
  --popup-background: 236, 233, 227, 0.4;
  --popup-title: 85, 85, 85, 1;
  --popup-content-1: 85, 85, 85, 1;
  --popup-content-2: 85, 85, 85, 0.6;
  --popup-secondary-cta-text: 98, 110, 162, 1;
  --popup-secondary-cta-border: 98, 110, 162, 1;
  --popup-selector-active: 98, 110, 162, 1;
  --popup-selector-active-text: 255, 255, 255, 1;
  --popup-selector-border: 85, 85, 85, 0.4;
  --popup-link: 98, 110, 162, 1;
  --chat-background: 85, 85, 85, 1;
  --chat-text: 255, 255, 255, 1;
}
