.Principal {
  /* Background */
  --page-background-from: 255, 255, 255, 1;
  --page-background-to: 255, 255, 255, 1;

  /* Header */
  --header-background: 72, 72, 72, 1;
  --header-content-1: 255, 255, 255, 1;
  --header-content-2: 255, 255, 255, 1;
  --header-content-3: 255, 255, 255, 0.6;

  /* Content */
  --main-content-1: 51, 51, 51, 1;
  --main-content-2: 51, 51, 51, 0.6;
  --main-content-3: 51, 51, 51, 0.4;
  --main-content-4: 51, 51, 51, 0.2;
  --main-content-5: 51, 51, 51, 0.1;
  --main-content-6: 51, 51, 51, 0.05;
  --content-background: 51, 51, 51, 0.05;
  --content-background-prominent: 51, 51, 51, 0.1;

  /* CTAs */
  --cta-primary: 39, 201, 15, 1;
  --cta-primary-text: 255, 255, 255, 1;
  --cta-primary-hover: 39, 201, 15, 1;
  --cta-secondary: 255, 255, 255, 0.1;
  --cta-secondary-text: 39, 201, 15, 1;
  --cta-secondary-border: 39, 201, 15, 1;
  --cta-secondary-hover: 34, 34, 34, 0.05;

  /* Inputs */
  --selector-active: 39, 201, 15, 1;
  --selector-active-text: 255, 255, 255, 1;
  --selector-border-1: 39, 201, 15, 1;
  --selector-border-2: 39, 201, 15, 0.2;
  --selector-isolated-active: 39, 201, 15, 1;

  --slider-active: 39, 201, 15, 1;
  --slider-inactive: 39, 201, 15, 0.2;
  --slider-handle: 255, 255, 255, 1;

  --reg-plate-text: 51, 51, 51, 1;
  --reg-plate: 51, 51, 51, 0.4;

  /* Quote summary */
  --summary-1: 59, 59, 59, 1;
  --summary-2: 59, 59, 59, 0.9;
  --summary-link: 255, 255, 255, 1;

  /* Footer */
  --footer-background: 72, 72, 72, 1;
  --footer-content-1: 255, 255, 255, 1;
  --footer-content-2: 255, 255, 255, 0.6;

  /* Link */
  --link: 39, 201, 15, 1;

  /* Errors */
  --error-text: 255, 0, 0, 1;
  --error-border: 255, 0, 0, 1;

  /* Progress */
  --progress-1: 39, 201, 15, 1;
  --progress-2: 39, 201, 15, 0.2;

  /* Navigation */
  --menu-background: 72, 72, 72, 1;
  --menu-content-1: 255, 255, 255, 1;
  --menu-content-2: 255, 255, 255, 0.1;

  /* Popups (Anything which appears on screen) */
  --popup-background: 34, 34, 34, 0.05;
  --popup-title: 51, 51, 51, 1;
  --popup-content-1: 51, 51, 51, 1;
  --popup-content-2: 51, 51, 51, 0.6;
  --popup-secondary-cta-text: 39, 201, 15, 1;
  --popup-secondary-cta-border: 39, 201, 15, 1;
  --popup-selector-active: 39, 201, 15, 1;
  --popup-selector-active-text: 255, 255, 255, 1;
  --popup-selector-border: 51, 51, 51, 0.4;
  --popup-link: 39, 201, 15, 1;

  /* Live Chat */
  --chat-background: 39, 201, 15, 1;
  --chat-text: 255, 255, 255, 1;
}
