.Stellantis {
  --page-background-from: 255, 255, 255, 1;
  --page-background-to: 255, 255, 255, 1;
  --header-background: 242, 242, 242, 1;
  --header-content-1: 34, 41, 57, 1;
  --header-content-2: 34, 41, 57, 1;
  --header-content-3: 34, 41, 57, 0.6;
  --main-content-1: 34, 41, 57, 1;
  --main-content-2: 34, 41, 57, 0.6;
  --main-content-3: 34, 41, 57, 0.4;
  --main-content-4: 34, 41, 57, 0.2;
  --main-content-5: 34, 41, 57, 0.1;
  --main-content-6: 34, 41, 57, 0.05;
  --content-background: 34, 41, 57, 0.05;
  --content-background-prominent: 34, 41, 57, 0.1;
  --cta-primary: 3, 66, 142, 1;
  --cta-primary-text: 255, 255, 255, 1;
  --cta-primary-hover: 3, 66, 142, 1;
  --cta-secondary: 255, 255, 255, 0.1;
  --cta-secondary-text: 3, 66, 142, 1;
  --cta-secondary-border: 3, 66, 142, 1;
  --cta-secondary-hover: 29, 29, 27, 0.05;
  --selector-active: 3, 66, 142, 1;
  --selector-active-text: 255, 255, 255, 1;
  --selector-border-1: 3, 66, 142, 1;
  --selector-border-2: 3, 66, 142, 0.2;
  --selector-isolated-active: 3, 66, 142, 1;
  --slider-active: 3, 66, 142, 1;
  --slider-inactive: 34, 41, 57, 0.2;
  --slider-handle: 255, 255, 255, 1;
  --reg-plate-text: 34, 41, 57, 1;
  --reg-plate: 34, 41, 57, 0.4;
  --progress-1: 34, 41, 57, 1;
  --progress-2: 34, 41, 57, 0.2;
  --summary-1: 34, 41, 57, 1;
  --summary-2: 34, 41, 57, 0.9;
  --footer-background: 34, 41, 57, 1;
  --footer-content-1: 255, 255, 255, 1;
  --footer-content-2: 255, 255, 255, 0.6;
  --link: 3, 66, 142, 1;
  /*--summary-link: 201, 12, 15, 1;*/
  --error-text: 255, 0, 0, 1;
  --error-border: 255, 0, 0, 1;
  --menu-background: 34, 41, 57, 1;
  --menu-content-1: 255, 255, 255, 1;
  --menu-content-2: 255, 255, 255, 0.1;
  --popup-background: 29, 29, 27, 0.05;
  --popup-title: 34, 41, 57, 1;
  --popup-content-1: 34, 41, 57, 1;
  --popup-content-2: 34, 41, 57, 0.6;
  --popup-secondary-cta-text: 3, 66, 142, 1;
  --popup-secondary-cta-border: 3, 66, 142, 1;
  --popup-selector-active: 3, 66, 142, 1;
  --popup-selector-active-text: 255, 255, 255, 1;
  --popup-selector-border: 34, 41, 57, 0.4;
  --popup-link: 3, 66, 142, 1;
  --chat-background: 3, 66, 142, 1;
  --chat-text: 255, 255, 255, 1;
}
