.Comfort {
  /* Background */
  --page-background-from: 255, 255, 255, 1;
  --page-background-to: 255, 255, 255, 1;

  /* Header */
  --header-background: 242, 242, 242, 1;
  --header-content-1: 204, 10, 38, 1;
  --header-content-2: 204, 10, 38, 1;
  --header-content-3: 204, 10, 38, 0.6;

  /* Content */
  --main-content-1: 0, 0, 0, 1;
  --main-content-2: 0, 0, 0, 0.6;
  --main-content-3: 0, 0, 0, 0.4;
  --main-content-4: 0, 0, 0, 0.2;
  --main-content-5: 0, 0, 0, 0.1;
  --main-content-6: 0, 0, 0, 0.05;
  --content-background: 0, 0, 0, 0.05;
  --content-background-prominent: 0, 0, 0, 0.1;

  /* CTAs */
  --cta-primary: 59, 211, 219, 1;
  --cta-primary-text: 0, 0, 0, 1;
  --cta-primary-hover: 49, 199, 207, 1;
  --cta-secondary: 255, 255, 255, 0.1;
  --cta-secondary-text: 204, 10, 38, 1;
  --cta-secondary-border: 204, 10, 38, 1;
  --cta-secondary-hover: 255, 255, 255, 0.1;

  /* Inputs */
  --selector-active: 204, 10, 38, 1;
  --selector-active-text: 255, 255, 255, 1;
  --selector-border-1: 204, 10, 38, 1;
  --selector-border-2: 204, 10, 38, 0.2;
  --selector-isolated-active: 204, 10, 38, 1;

  --slider-active: 204, 10, 38, 1;
  --slider-inactive: 0, 0, 0, 0.2;
  --slider-handle: 255, 255, 255, 1;

  --reg-plate-text: 0, 0, 0, 1;
  --reg-plate: 0, 0, 0, 0.4;

  /* Quote summary */
  --summary-1: 0, 0, 0, 1;
  --summary-2: 0, 0, 0, 0.8;
  --summary-link: 204, 10, 38, 1;

  /* Footer */
  --footer-background: 204, 10, 38, 1;
  --footer-content-1: 255, 255, 255, 1;
  --footer-content-2: 255, 255, 255, 0.6;

  /* Link */
  --link: 204, 10, 38, 1;

  /* Errors */
  --error-text: 255, 0, 0, 1;
  --error-border: 255, 0, 0, 1;

  /* Progress */
  --progress-1: 59, 211, 219, 1;
  --progress-2: 59, 211, 219, 0.2;

  /* Navigation */
  --menu-background: 204, 10, 38, 1;
  --menu-content-1: 255, 255, 255, 1;
  --menu-content-2: 255, 255, 255, 0.1;

  /* Popups (Anything which appears on screen) */
  --popup-background: 0, 0, 0, 0.05;
  --popup-title: 0, 0, 0, 1;
  --popup-content-1: 0, 0, 0, 1;
  --popup-content-2: 0, 0, 0, 0.6;
  --popup-secondary-cta-text: 204, 10, 38, 1;
  --popup-secondary-cta-border: 204, 10, 38, 1;
  --popup-selector-active: 204, 10, 38, 1;
  --popup-selector-active-text: 255, 255, 255, 1;
  --popup-selector-border: 0, 0, 0, 0.4;
  --popup-link: 204, 10, 38, 1;

  /* Live Chat */
  --chat-background: 204, 10, 38, 1;
  --chat-text: 255, 255, 255, 1;
}
