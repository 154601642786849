.GeneralAccident {
  --page-background-from: 255, 255, 255, 1;
  --page-background-to: 255, 255, 255, 1;
  --header-background: 229, 246, 252, 1;
  --header-content-1: 0, 114, 187, 1;
  --header-content-2: 0, 114, 187, 1;
  --header-content-3: 0, 114, 187, 0.6;
  --main-content-1: 45, 45, 45, 1;
  --main-content-2: 45, 45, 45, 0.6;
  --main-content-3: 45, 45, 45, 0.4;
  --main-content-4: 45, 45, 45, 0.2;
  --main-content-5: 45, 45, 45, 0.1;
  --main-content-6: 45, 45, 45, 0.05;
  --content-background: 45, 45, 45, 0.05;
  --content-background-prominent: 45, 45, 45, 0.1;
  --cta-primary: 121, 37, 133, 1;
  --cta-primary-text: 255, 255, 255, 1;
  --cta-primary-hover: 134, 59, 145, 1;
  --cta-secondary: 255, 255, 255, 0.1;
  --cta-secondary-text: 0, 114, 187, 1;
  --cta-secondary-border: 0, 114, 187, 1;
  --cta-secondary-hover: 45, 45, 45, 0.05;
  --selector-active: 0, 114, 187, 1;
  --selector-active-text: 255, 255, 255, 1;
  --selector-border-1: 0, 114, 187, 1;
  --selector-border-2: 0, 114, 187, 0.2;
  --selector-isolated-active: 0, 114, 187, 1;
  --slider-active: 0, 114, 187, 1;
  --slider-inactive: 0, 114, 187, 0.2;
  --slider-handle: 255, 255, 255, 1;
  --reg-plate-text: 45, 45, 45, 1;
  --reg-plate: 45, 45, 45, 0.4;
  --progress-1: 0, 162, 229, 1;
  --progress-2: 0, 162, 229, 0.2;
  --summary-1: 0, 114, 187, 1;
  --summary-2: 0, 114, 187, 0.9;
  --footer-background: 229, 246, 252, 1;
  --footer-content-1: 0, 114, 187, 1;
  --footer-content-2: 0, 114, 187, 0.6;
  --link: 0, 114, 187, 1;
  --summary-link: 0, 114, 187, 1;
  --error-text: 255, 0, 0, 1;
  --error-border: 255, 0, 0, 1;
  --menu-background: 0, 114, 187, 1;
  --menu-content-1: 255, 255, 255, 1;
  --menu-content-2: 255, 255, 255, 0.1;
  --popup-background: 45, 45, 45, 0.05;
  --popup-title: 45, 45, 45, 1;
  --popup-content-1: 45, 45, 45, 1;
  --popup-content-2: 45, 45, 45, 0.6;
  --popup-secondary-cta-text: 0, 114, 187, 1;
  --popup-secondary-cta-border: 0, 114, 187, 1;
  --popup-selector-active: 0, 114, 187, 1;
  --popup-selector-active-text: 255, 255, 255, 1;
  --popup-selector-border: 45, 45, 45, 0.4;
  --popup-link: 0, 114, 187, 1;
  --chat-background: 121, 37, 133, 1;
  --chat-text: 255, 255, 255, 1;
}
