@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Raleway:wght@400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Raleway', system-ui, sans-serif;
    -webkit-font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum';
  }
  ::-webkit-credentials-auto-fill-button {
    visibility: hidden;
  }

  .pre-wrap {
    white-space: pre-wrap;
  }
}

@layer components {
  .link-with-icon {
    @apply flex gap-3 items-center;
  }

  .form-field-spacing {
    @apply mb-8 lg:mb-12;
  }

  .form-subtitle {
    @apply text-lg mb-8 lg:mb-12;
  }

  .form-subtitle-compact {
    @apply text-lg mb-6 lg:mb-8;
  }

  .link {
    @apply text-link font-semibold underline;
  }

  .summary-link {
    @apply text-summary-link underline;
  }

  .apple-pay-button {
    @apply !w-full !h-14 lg:!h-16;
    -apple-pay-button-style: white-outline;
  }

  .popup-link {
    @apply font-semibold underline text-popup-link;
  }

  .strike-throuh-1 {
    text-decoration-skip-ink: none;
    @apply underline decoration-1;
  }

  .strike-throuh-2 {
    text-decoration-skip-ink: none;
    @apply underline decoration-2;
  }
}

/* Live Chat Web Messenger Styling */
.custom_fc_frame {
  min-width: 77px !important;
}

@media screen and (min-width: 1024px) {
  .custom_fc_frame {
    right: 40px !important;
  }
}

/* Onetrust cookie banner styling */
#ot-sdk-btn-floating.ot-floating-button button {
  display: flex;
  align-items: center;
  justify-content: center;
}
