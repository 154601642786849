.Halifax {
  --page-background-from: 255, 255, 255, 1;
  --page-background-to: 255, 255, 255, 1;
  --header-background: 0, 94, 184, 1;
  --header-content-1: 255, 255, 255, 1;
  --header-content-2: 255, 255, 255, 1;
  --header-content-3: 255, 255, 255, 0.6;
  --main-content-1: 7, 29, 73, 1;
  --main-content-2: 7, 29, 73, 0.6;
  --main-content-3: 7, 29, 73, 0.4;
  --main-content-4: 7, 29, 73, 0.2;
  --main-content-5: 7, 29, 73, 0.1;
  --main-content-6: 7, 29, 73, 0.05;
  --content-background: 0, 94, 184, 0.05;
  --content-background-prominent: 0, 94, 184, 0.1;
  --cta-primary: 0, 94, 184, 1;
  --cta-primary-text: 255, 255, 255, 1;
  --cta-primary-hover: 26, 110, 191, 1;
  --cta-secondary: 255, 255, 255, 0.1;
  --cta-secondary-text: 0, 94, 184, 1;
  --cta-secondary-border: 0, 94, 184, 1;
  --cta-secondary-hover: 0, 94, 184, 0.05;
  --selector-active: 70, 152, 232, 1;
  --selector-active-text: 255, 255, 255, 1;
  --selector-border-1: 70, 152, 232, 1;
  --selector-border-2: 70, 152, 232, 0.2;
  --selector-isolated-active: 70, 152, 232, 1;
  --slider-active: 70, 152, 232, 1;
  --slider-inactive: 70, 152, 232, 0.2;
  --slider-handle: 255, 255, 255, 1;
  --reg-plate-text: 7, 29, 73, 1;
  --reg-plate: 7, 29, 73, 0.4;
  --progress-1: 0, 162, 179, 1;
  --progress-2: 0, 162, 179, 0.2;
  --summary-1: 7, 29, 73, 1;
  --summary-2: 7, 29, 73, 0.9;
  --footer-background: 0, 94, 184, 1;
  --footer-content-1: 255, 255, 255, 1;
  --footer-content-2: 255, 255, 255, 0.6;
  --link: 0, 94, 184, 1;
  --summary-link: 0, 94, 184, 1;
  --error-text: 255, 0, 0, 1;
  --error-border: 255, 0, 0, 1;
  --menu-background: 0, 94, 184, 1;
  --menu-content-1: 255, 255, 255, 1;
  --menu-content-2: 255, 255, 255, 0.1;
  --popup-background: 0, 94, 184, 0.05;
  --popup-title: 7, 29, 73, 1;
  --popup-content-1: 7, 29, 73, 1;
  --popup-content-2: 7, 29, 73, 0.6;
  --popup-secondary-cta-text: 0, 94, 184, 1;
  --popup-secondary-cta-border: 0, 94, 184, 1;
  --popup-selector-active: 70, 152, 232, 1;
  --popup-selector-active-text: 255, 255, 255, 1;
  --popup-selector-border: 7, 29, 73, 0.4;
  --popup-link: 0, 94, 184, 1;
  --chat-background: 70, 152, 232, 1;
  --chat-text: 255, 255, 255, 1;
}
